import { Box } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import Image from "next/image";

export type PepProps = {
  pep: {
    img: string;
    alt?: string;
  };
  pepSize?: ResponsiveStyleValue<number | string>;
};

const Pep: React.FC<PepProps> = ({ pep, pepSize = "145px" }): JSX.Element => {
  const defaultAlt = "pep the cute little chili pepper who wants to help you study";
  const { img, alt } = pep;
  return (
    <Box
      sx={{
        width: pepSize,
        height: pepSize,
        position: "relative",
        opacity: 1,
        zIndex: 3,
      }}>
      <Image style={{ zIndex: 11 }} alt={alt || defaultAlt} src={`/img/mascot/${img}.svg`} fill />
    </Box>
  );
};
export default Pep;
