import { Box, Card, Stack, Typography } from "@mui/material";
import { useGetStatsQuery } from "@/store/api/graphql/library.extended.api";
import Link from "@/components-modern/atoms/link/Link";
import Loading from "@/components/Library/UI/Loading/Loading";
import { useTrackEvent } from "@/services";
import { Logger } from "@/utils/logger";
import { Stats } from "@/store/api/graphql/generated/types";

const LOGID = "fiveable:frontend:components-modern:molecules:browse-by-category-card:BrowseByCategoryCard";

const log = Logger(LOGID);

const BANNER_TITLE = "Browse by category";
const SECTION_TITLE = "Browse all subject categories";

const BrowseByCategoryCard = ({
  variant = "section",
  stats,
}: {
  variant: "banner" | "section";
  stats?: Stats;
}): JSX.Element => {
  const { trackEvent } = useTrackEvent();

  const { data, isLoading, error } = useGetStatsQuery(
    {}, // Query options (empty in this case)
    { skip: Boolean(stats) } // Hook options
  );

  const onClickCategory = (subject) => {
    trackEvent({
      category: "Browse By Category Card Section",
      action: "Click Category Link",
      other: { ...subject, variant },
    });
  };

  const renderCategories = () => {
    const subjectsByCategoryBranch =
      stats?.countSubjectsByCategoryBranch || data?.stats.countSubjectsByCategoryBranch;

    if (isLoading) {
      return <Loading message="Loading subject categories..." />;
    }

    if (error) {
      log.error(error);
      return <Typography color="error">Error loading subject categories</Typography>;
    }

    if (!stats?.countSubjectsByCategoryBranch && !data?.stats?.countSubjectsByCategoryBranch) {
      return <Typography>No subject categories available</Typography>;
    }

    return subjectsByCategoryBranch.map((subject) => (
      <Link
        key={subject.slug}
        variant="contained"
        href={`/categories/${subject.slug}`}
        onClick={() => {
          onClickCategory(subject);
        }}>
        {subject.name} ({subject.count})
      </Link>
    ));
  };

  if (variant === "section") {
    return (
      <Stack gap={2.5}>
        <Typography variant="h4">{SECTION_TITLE}</Typography>
        <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap", maxWidth: "700px" }}>
          {renderCategories()}
        </Box>
      </Stack>
    );
  }

  return (
    <Card
      sx={(theme) => ({ background: theme.palette.background.boxHighlight, textAlign: "center", border: 1 })}>
      <Stack gap={2.5}>
        <Typography variant="h4">{BANNER_TITLE}</Typography>
        <Box sx={{ display: "flex", gap: 1.5, flexWrap: "wrap", justifyContent: "center" }}>
          {renderCategories()}
        </Box>
      </Stack>
    </Card>
  );
};

export default BrowseByCategoryCard;
