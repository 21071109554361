import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { PEP_IMAGES } from "@hours/constants";
import Pep from "@/components-modern/atoms/pep/Pep";
import { Button } from "@/components-modern/atoms/button/Button";
import { CustomAutocompleteOption } from "@/components/Design/CustomAutocomplete/CustomAutocomplete";
import { useTrackEvent } from "@/services";
import { predictMyTestAnalyticsInfo } from "@/utils/analyticsConstants/experimentsAnalyticsInfo";
import GenericDialog from "../generic-dialog/GenericDialog";
import { SubjectUnitTopicSearch, ContentLinkData } from "../subject-unit-topic-search/SubjectUnitTopicSearch";
import { PredictMyTestLoadingScreen } from "./PrediectMyTestLoadingScreen";
import { pepIconSX } from "./shared";

// Predict My Test:
// - use the SubjectUnitTopic search to have a user view a guide
// - there is no 'predicting' or ai here, it's an experiment around
//   the positioning of our already generated content

enum TestDataType {
  SUBJECT = "SUBJECT",
  UNIT = "UNIT",
  CONTENT = "CONTENT",
}

export const PredictMyTest: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isNavigating, setIsNavigating] = useState<boolean>(false);
  const [testData, setTestData] = useState<ContentLinkData>();

  const { trackEvent } = useTrackEvent();

  const handleOnNavigateToGuide = (contentLinkData: ContentLinkData) => {
    setIsNavigating(true);
    setTestData(contentLinkData);
  };

  const handleOnOpenDialog = () => {
    setDialogOpen(true);
    trackEvent({ ...predictMyTestAnalyticsInfo.CLICK_PREDICT_MY_TEST_BUTTON });
  };

  const handleOnCloseDialog = () => {
    // if they're navigating, don't close
    if (isNavigating) return;
    setDialogOpen(false);
    trackEvent({ ...predictMyTestAnalyticsInfo.CLOSE_PREDIECT_MY_TEST_DIALOG });
  };

  // analytics tracking function to keep things DRY
  const handleOnSelectTestData = (option: CustomAutocompleteOption, type: TestDataType) => {
    const trackEventData = {
      ...predictMyTestAnalyticsInfo[`SELECT_PREDICT_MY_TEST_${type}`],
      other: {
        subject: type === TestDataType.SUBJECT ? option.id : testData?.subject?.id,
        unit: type === TestDataType.SUBJECT ? option.id : testData?.unit?.id,
        content: type === TestDataType.CONTENT ? option.id : undefined,
      },
    };
    trackEvent(trackEventData);
  };

  // wrappers around handleOnSelectTestData to use for specific fields
  const handleOnSubjectSelect = (subjectOption: CustomAutocompleteOption) => {
    handleOnSelectTestData(subjectOption, TestDataType.SUBJECT);
  };

  const handleOnUnitSelect = (unitOption: CustomAutocompleteOption) => {
    handleOnSelectTestData(unitOption, TestDataType.UNIT);
  };

  const handleOnContentSelect = (contentOption: CustomAutocompleteOption) => {
    handleOnSelectTestData(contentOption, TestDataType.CONTENT);
  };

  return (
    <>
      <GenericDialog
        id="predict-my-test-dialog"
        open={dialogOpen}
        onCancel={handleOnCloseDialog}
        showCloseButton={!isNavigating}
        dialogContentStyles={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isNavigating ? (
          <PredictMyTestLoadingScreen testData={testData} />
        ) : (
          <Grid
            container
            sx={{
              height: "80%",
              display: "flex",
              justifyContent: "center",
            }}
            rowSpacing={1}>
            <>
              <Grid item xs={12} sx={{ pb: { xs: 0, md: 2 } }}>
                <Typography variant="h1">Predict your next test</Typography>
                <Typography variant="subtitle2">We know what your teacher is thinking...</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                order={{ xs: 1, md: 2 }}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={pepIconSX}>
                  <Pep pep={PEP_IMAGES.pepRainbowMagic} pepSize="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                <SubjectUnitTopicSearch
                  containerStyles={{ width: "100%" }}
                  fieldContainerStyles={{ width: "100%", my: 2 }}
                  onClickNavigateButton={handleOnNavigateToGuide}
                  buttonLabel="Show me the money!"
                  onSubjectSelect={handleOnSubjectSelect}
                  onUnitSelect={handleOnUnitSelect}
                  onContentSelect={handleOnContentSelect}
                />
              </Grid>
            </>
          </Grid>
        )}
      </GenericDialog>
      <Button sx={{ width: "250px" }} onClick={handleOnOpenDialog} variant="secondary">
        <Typography variant="h4">Predict my test!</Typography>
      </Button>
    </>
  );
};
