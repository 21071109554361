import Link from "@/components-modern/atoms/link/Link";
import { Box, Grid, Typography } from "@mui/material";
import Pep from "@/components-modern/atoms/pep/Pep";
import { PEP_IMAGES } from "@hours/constants";
import { useTrackEvent } from "@/services";
import { predictMyTestAnalyticsInfo } from "@/utils/analyticsConstants/experimentsAnalyticsInfo";
import { useReveal } from "@/hooks/useReveal";
import { ContentLinkData, getGuideLink } from "../subject-unit-topic-search/SubjectUnitTopicSearch";
import { pepIconSX } from "./shared";

// this is a loading screen for the prediect my test feature
// it just waits 3 seconds and then we expose a link you can click on
export const PredictMyTestLoadingScreen: React.FC<{ testData: ContentLinkData }> = ({ testData }) => {
  const { trackEvent } = useTrackEvent();

  const [showHeading1] = useReveal(1000);
  const [showHeading2] = useReveal(2000);
  const [showLink, setShowLink] = useReveal(3000);

  const handleOnLinkClick = () => {
    setShowLink(false);
    trackEvent({
      ...predictMyTestAnalyticsInfo.CLICK_PREDICT_MY_TEST_GUIDE_LINK,
      other: {
        ...testData,
      },
    });
  };

  const linkUrl = getGuideLink(testData);

  return (
    <Grid container sx={{ display: "flex", height: "50%" }}>
      <Grid item xs={12} sx={{ pb: { xs: 0, md: 2 } }}>
        <Typography variant="h4" sx={{ textAlign: "left", mb: 2 }}>
          crunching the numbers...
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: "right", mb: 3, visibility: showHeading1 ? "visible" : "hidden" }}>
          ...finding exactly what you need
        </Typography>
        <Typography variant="h2" sx={{ textAlign: "left", visibility: showHeading2 ? "visible" : "hidden" }}>
          are you ready to ace this test?!
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ height: "225px", justifyContent: "center", display: "flex", alignItems: "center" }}>
        <Box sx={pepIconSX}>
          <Pep pep={PEP_IMAGES.pepThinkingAnimated} pepSize="100%" />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
        <Link
          href={showLink ? linkUrl : "/#"}
          aria-disabled={!showLink}
          style={{ pointerEvents: !showLink ? "none" : "auto" }}
          onClick={handleOnLinkClick}
          variant={!showLink ? "contained" : "secondary"}>
          <Typography
            variant="h5"
            sx={{
              color: showLink ? "white" : "darkGray",
            }}>
            {!showLink ? "Loading..." : "Let's do this!"}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
