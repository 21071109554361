import Layout from "@/components/Layouts/Layout";
import BrowseSubjectsByCategoryBranch from "@/components/Library/Resources/BrowseSubjectsByCategoryBranch";
import { useAuthModals } from "@/hooks/modals/useAuthModals";
import { wrapper } from "@/store";
import { Stats, SubjectsByCategoryBranchOrSubBranch } from "@/store/api/graphql/generated/types";
import libraryApi from "@/store/api/graphql/library.extended.api";
import { SIX_HOURS_IN_SECONDS, STUDENT_REQ_BRANCH } from "@/utils/constants";
import useProfile from "@/utils/useProfile";
import { useRouter } from "next/router";
import { useEffect } from "react";

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  const subjectsByCategoryBranchResult = await store.dispatch(
    libraryApi.endpoints.getSubjectsByCategoryBranch.initiate({})
  );
  const statsResult = await store.dispatch(libraryApi.endpoints.getStats.initiate({}));

  // Do not list subjects that are user created, for now. These will all be created within the same branch
  const filteredSubjectsByCategoryBranch =
    subjectsByCategoryBranchResult?.data?.subjectsByCategoryBranch?.filter(
      (branch) => branch.name !== STUDENT_REQ_BRANCH
    );

  return {
    props: {
      subjectsByCategoryBranch: filteredSubjectsByCategoryBranch,
      stats: statsResult?.data?.stats,
      revalidate: SIX_HOURS_IN_SECONDS,
    },
  };
});

const Index = ({
  subjectsByCategoryBranch,
  stats,
}: {
  subjectsByCategoryBranch: Array<SubjectsByCategoryBranchOrSubBranch>;
  stats: Stats;
}): JSX.Element => {
  const router = useRouter();
  const { showExpandedSignupModal, showExpandedLoginModal } = useAuthModals();
  const { isLoggedIn } = useProfile();

  useEffect(() => {
    // This effect will open an auth modal if login or signup action in URL query params:
    //  - library.fiveable.me?action=signup
    //  - library.fiveable.me?action=login
    if (!router.isReady || isLoggedIn) return;

    const action = Array.isArray(router.query.action) ? router.query.action[0] : router.query.action;

    const modalActions = {
      signup: () => showExpandedSignupModal({ type: "webflow", label: "Sign up" }),
      login: () => showExpandedLoginModal({ type: "webflow", label: "Log in" }),
    };

    if (action in modalActions) {
      modalActions[action]();
    }
  }, [router.isReady, router.query, isLoggedIn]);

  return (
    <Layout
      title="Focused study guides for every class | Fiveable"
      description="Exactly what you need to know for your next test, organized in an easy-to-read study guide. We did the hard part for you, simplifying every textbook chapter."
      navDefaultState
      hideNavDesktop>
      <BrowseSubjectsByCategoryBranch subjectsByCategoryBranch={subjectsByCategoryBranch} stats={stats} />
    </Layout>
  );
};

export default Index;
