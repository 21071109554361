import { useEffect } from "react";
import { Box } from "@mui/material";

const TestimonialWidget = (): JSX.Element => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.senja.io/widget/5733abd7-ec89-47d6-848c-c8bf7ca860c7/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <Box sx={{ position: "relative", zIndex: 1, marginBottom: 0 }}>
      <div
        className="senja-embed"
        data-id="5733abd7-ec89-47d6-848c-c8bf7ca860c7"
        data-mode="shadow"
        data-lazyload="false"
        style={{ display: "block" }}
      />
    </Box>
  );
};

export default TestimonialWidget;
